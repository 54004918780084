import React, { ReactNode, useEffect, useState } from 'react'
import { Typography, Row, Card } from 'antd'
import ReactMarkdown from 'react-markdown'

import AboutUsMD from '../../assets/info/AboutUs.md'
import TermsMD from '../../assets/info/Terms.md'
import PrivacyMD from '../../assets/info/Privacy.md'
import ContactUsMD from '../../assets/info/ContactUs.md'

const { Title, Text, Paragraph } = Typography

type TLevel = 1 | 2 | 3 | 4 | 5 | undefined

function customHeader(props: { level: number; children: ReactNode }) {
  return (
    <Row justify="center">
      <Title level={props.level as TLevel}>{props.children}</Title>
    </Row>
  )
}

function customEditDate(props: { children: ReactNode }) {
  return (
    <Row justify="end">
      <Text type="secondary">{props.children}</Text>
    </Row>
  )
}

function customParagraph(props: { children: ReactNode }) {
  return (
    <Row>
      <Paragraph>{props.children}</Paragraph>
    </Row>
  )
}

function GetMarkdown(props: { mdFile: '*.md' }) {
  const { mdFile } = props
  const [fileContent, setFileContent] = useState('')

  useEffect(() => {
    const url = new URL(location.origin)
    url.pathname = mdFile
    fetch(url.toString())
      .then((res) => res.text())
      .then((text) => setFileContent(text))
  }, [])

  return (
    <Card className="cardContent" bordered={false}>
      <ReactMarkdown
        components={{
          h1: customHeader,
          h6: customEditDate,
          p: customParagraph,
        }}
      >
        {fileContent}
      </ReactMarkdown>
    </Card>
  )
}

export const AboutUs = () => <GetMarkdown mdFile={AboutUsMD} />
export const ContactUs = () => <GetMarkdown mdFile={ContactUsMD} />
export const Privacy = () => <GetMarkdown mdFile={PrivacyMD} />
export const Terms = () => <GetMarkdown mdFile={TermsMD} />
