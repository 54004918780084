import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Card, Spin, Row, Col } from 'antd';
import AuditorTable from './components/AuditorTable';
import { auditor as auditorApi } from '@rap/api';
import { Helmet } from 'react-helmet-async';
import AuditorTableOptions from './components/AuditorTable/AuditorTableOptions'
import './style.css';
import { set } from 'lodash';

const Browse = () => {
  const [isLoading, setLoading] = useState(true);
  const [auditorList, setAuditorList] = useState([]);
  const [skip, setSkip] = useState(0);
  const [numAuditors, setNumAuditors] = useState(0);
  const limit = 10;
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);

  const [name, setName] = useState('')

  const [firmName, setFirmName] = useState('')
  const [location, setLocation] = useState<string[]>([])

  const [searchParams, setSearchParams] = useSearchParams()

  function getAuditors(){
    setLoading(true)
    auditorApi.getPaginateVerifiedAuditors(skip, limit, name, firmName, location).then((res) => {
      setAuditorList(res.data.auditors)
      setNumAuditors(res.data.count);
    })
    setLoading(false)
  }

  useEffect(() => {
    if(searchParams.get("auditorName") !== null){
      var string_copy = ('' + searchParams.get("auditorName"))
      setName(string_copy)
      setShow(true)
    }
  }, []);

  useEffect(() => {
    getAuditors()
  }, [skip, name, firmName, location])

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "url": "https://www.rateauditor.org/?page=/browse",
    "dateModified": "2024-09-16T18:32:28+01:00",
    "priority": "0.8"
  }

  return (
    <>
     <Helmet>
		    <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>	
		  </Helmet>
    <div className="browse-container">
      <Row justify="center" align="middle">
        <Col xs={36} sm={30} md={25} lg={21}>
          <Card
            className="cardContent"
            bordered={false}
            style={{
              borderRadius: '10px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              padding: '20px',
              margin: '20px 0',
            }}
          >
            {isLoading ? (
              <div className="loading-container">
                <Spin size="large" />
              </div>
            ) : (
              <div>
              <AuditorTableOptions
                name={name}
                firmName={firmName}
                location={location}
                show={show}
                setShow={setShow}
                setName={setName}
                setFirmName={setFirmName}
                setLocation={setLocation}
                setSkip={setSkip}
                setPage={setPage}
              />
              <AuditorTable
                isLoading={isLoading}
                auditorList={auditorList}
                setSkip={setSkip}
                numAuditors={numAuditors}
                page={page}
                setPage={setPage}
              />
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </div>
    </>
  );
};

export default Browse;
