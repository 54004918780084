// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { Typography, Col, Row, Alert, Table, TableProps } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { Link } from 'react-router-dom'
import { SearchOutlined, FrownTwoTone, MehTwoTone, SmileTwoTone } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import dayjs from '../../../utils/dayjs'

import styles from './style.module.css'
import { CountryList } from './Location'
import AuditorTableOptions from './AuditorTableOptions'

const { Text } = Typography

interface IRateFaceProps {
  count: number
  rate: number
}

interface IAuditorTableProps {
  numAuditors: number
  isLoading: boolean
  setSkip:(skip: number) => void
  auditorList: rateauditor.api.auditor.IAuditorInfo[]
  page: number
  setPage: (page: number) => void
}

function RateFace(props: IRateFaceProps) {
  if (props.count === 0) {
    return <></>
  } else if (props.rate < 2) {
    return <FrownTwoTone className={styles['rate-face']} twoToneColor="#eb2f96" />
  } else if (props.rate < 4) {
    return <MehTwoTone className={styles['rate-face']} twoToneColor="#ffbf00" />
  } else {
    return <SmileTwoTone className={styles['rate-face']} twoToneColor="#52c41a" />
  }
}

function AuditorTable(props: IAuditorTableProps) {
  const { isLoading, auditorList, setSkip, numAuditors, page, setPage } = props

  // let sortedInfo = props.sortedInfo || {}
  // const filteredInfo = props.filteredInfo || {}

  const handleChange: TableProps<rateauditor.api.auditor.IAuditorInfo>['onChange'] = (
    pagination
  ) => {
    setPage(pagination.current)
    setSkip(pagination.current * 10 - 10)
  }

  const columns: ColumnsType<rateauditor.api.auditor.IAuditorInfo> = [
    /* eslint-disable react/display-name */
    {
      title: 'Name',
      dataIndex: 'last_name',
      key: 'name',
      render: (data, row, index) => {
        const name = `${row.last_name || ''}, ${row.first_name || ''} ${row.middle_name || ''}`
        return (<Link to={`/auditor/${row._id}`}>{name}</Link>)
      },
      fixed: 'left',
      width: '20%',
    },
    {
      title: 'Firm',
      dataIndex: 'firm_name',
      key: 'firm',
      render: (text, row, index) => {
        return (text)
      },
      width: '20%',
    },
    {
      title: 'Location',
      dataIndex: 'firm_issuing_city',
      key: 'location',
      render: (text, row, index) => {
        return (
          <>
            <Text className={styles.location}>{row.firm_issuing_city}</Text>
            <Text
              className={styles.location}
              type="secondary"
            >{`${row.firm_issuing_country} / ${row.firm_issuing_state}`}</Text>
          </>
        )
      },
      width: '20%',
    },
  ]

  return (
    <div>
      <Row justify="center">
        <Col xs={24} sm={0}>
          <Alert
            message="The current screen size may affect the display of the table, it is recommended to use landscape viewing!"
            type="info"
            showIcon
          />
        </Col>
      </Row>
      <div>
        <Table
          style={{marginTop: "10px"}}
          columns={columns}
          onChange={handleChange}
          dataSource={auditorList}
          rowKey={(record) => record._id}
          loading={isLoading}
          pagination={numAuditors < 10 ? false : {
            total: numAuditors,
            defaultPageSize: 10,
            showSizeChanger: false,
            position: ['bottomRight'],
            current: page
          }}
          size="small"
          scroll={{ x: 'max-content' }}
          bordered
          sticky
        />
      </div>
    </div>
  )
}

export default AuditorTable
